@use "sass:math";

.form-check-input[type='checkbox'] {
    &:indeterminate,
    &:checked {
        &,
        .form-check-solid & {
            background-color: var(--bs-primary);
            border-color: var(--bs-primary);
        }

        .form-check-success &,
        .form-check-solid.form-check-success & {
            background-color: var(--bs-success);
            border-color: var(--bs-success);
        }
        .form-check-info &,
        .form-check-solid.form-check-info & {
            background-color: var(--bs-info);
            border-color: var(--bs-info);
        }
        .form-check-warning &,
        .form-check-solid.form-check-warning & {
            background-color: var(--bs-warning);
            border-color: var(--bs-warning);
        }
        .form-check-danger &,
        .form-check-solid.form-check-danger & {
            background-color: var(--bs-danger);
            border-color: var(--bs-danger);
        }
    }
}
$switchWidth: 50px;
$switchHeight: 30px;
$switchBorder: #d1d7e3;
$switchBackground: #eff2f5;
$switchDot: var(--bs-white);
$switchActive: var(--bs-primary);
$switchBorderActive: var(--bs-primary);
$switchBorderInActive: var(--bs-white);

.form-switch-loader {
    input {
        display: none;
        & + div {
            width: $switchWidth;
            height: $switchHeight;
            border: 1px solid transparent;
            background: $switchBackground;
            border-radius: math.div($switchWidth, 2);
            vertical-align: top;
            position: relative;
            display: inline-block;
            user-select: none;
            transition: all 0.4s ease;
            cursor: pointer;
        }
        &:not(:checked) + div {
            &:before {
                content: '';
                position: absolute;
                left: 3px;
                top: 3px;
                width: $switchHeight - 8;
                height: $switchHeight - 8;
                background: $switchDot;
                pointer-events: none;
                border-radius: inherit;
            }
            &:after {
                content: '';
                left: -1px;
                top: -1px;
                width: $switchHeight;
                height: $switchHeight;
                border: 4px solid transparent;
                border-top-color: $switchBorderActive;
                border-radius: 50%;
                position: absolute;
                opacity: 0;
            }
        }
        &:checked + div {
            background: $switchActive;
            border: 1px solid transparent;
            &:before {
                content: '';
                right: -1px;
                top: -1px;
                width: $switchHeight;
                height: $switchHeight;
                border: 4px solid transparent;
                border-top-color: $switchBorderInActive;
                border-radius: 50%;
                position: absolute;
                opacity: 0;
            }
            &:after {
                content: '';
                position: absolute;
                right: 3px;
                top: 3px;
                width: $switchHeight - 8;
                height: $switchHeight - 8;
                background: $switchDot;
                pointer-events: none;
                border-radius: inherit;
            }
        }
    }
    &.load {
        input {
            & + div {
                width: $switchHeight;
                margin: 0 math.div($switchWidth - $switchHeight, 2);
                &:after {
                    opacity: 1;
                    animation: rotate 0.9s infinite linear;
                    animation-delay: 0.2s;
                }
                &:before {
                    opacity: 1;
                    animation: rotate 0.9s infinite linear;
                    animation-delay: 0.2s;
                }
            }
        }
    }
    &:hover {
        input {
            &:disabled + div {
                border-color: $switchBorder;
            }
            &:checked + div {
                background: $switchBorderActive;
            }
        }
    }
}

@keyframes rotate {
    0%,
    15% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(290deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
